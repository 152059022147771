import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../../shared/hooks/useAuth';
// utils
import { fData } from '../../../../../shared/utils/formatNumber';
// _mock
import { countries } from '../../mock/countries';
// components
import { CustomFile } from '../../../../../shared/components/upload';
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../../shared/components/hook-form';
import { API, API_ENDPOINTS } from '../../../../../shared/utils/api';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  photoURL: CustomFile | string | null;
  phoneNumber: string | null;
  country: string | null;
  address: string | null;
  stateOrRegion: string | null;
  city: string | null;
  zipCode: string | null;
  about: string | null;
  isPublic: boolean;
};

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const { user, initialize } = useAuth();

  const isAdminChangeDisable = useMemo(() => {
    if (user?.user_role) {
      const userRoles = [...(user?.user_role ?? {})];
      return userRoles.some((el) => el?.type === 'admin');
    }
    return false;
  }, [user]);

  const UpdateUserSchema = Yup.object().shape({
    username: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email(),
    user_surname: Yup.string().required('Surname is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    country: Yup.string().required('Country is required'),
    address: Yup.string().required('Address is required'),
    stateOrRegion: Yup.string().required('State and Region is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('ZipCode is required'),
  });

  const defaultValues = {
    username: user?.username || '',
    user_surname: user?.user_surname || '',
    email: user?.email || '',
    photoURL: user?.avatarUrl || '',
    phoneNumber: user?.phoneNumber || '',
    country: user?.country || '',
    address: user?.address || '',
    stateOrRegion: user?.stateOrRegion || '',
    city: user?.city || '',
    zipCode: user?.zipCode || '',
    about: user?.about || '',
    isPublic: user?.isPublic || false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const formData = new FormData();

    Object.entries(data).forEach(([formProp, value]) => {
      if (formProp === 'photoURL' && value !== defaultValues.photoURL) {
        // @ts-ignore
        formData.append('file', data[formProp]);
      }
    });

    try {
      if (data) {
        await API.put<any>(API_ENDPOINTS.USER_UPDATE_GENERAL.replace(':id', user?.id), data);

        if (formData.has('file')) {
          await API.put<any>(API_ENDPOINTS.UPDATE_AVATAR.replace(':id', user?.id), formData);
        }
      }

      initialize();
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="photoURL"
              maxSize={3145728}
              onDrop={handleDrop}
              disabled={isAdminChangeDisable}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />

            <RHFSwitch
              name="isPublic"
              labelPlacement="start"
              label="Public Profile"
              sx={{ mt: 5 }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="username" label="Name" disabled={isAdminChangeDisable} />
              <RHFTextField name="email" label="Email Address" disabled={isAdminChangeDisable} />
              <RHFTextField name="user_surname" label="Surname" disabled={isAdminChangeDisable} />

              <RHFTextField
                name="phoneNumber"
                label="Phone Number"
                disabled={isAdminChangeDisable}
              />
              <RHFTextField name="address" label="Address" disabled={isAdminChangeDisable} />

              <RHFSelect
                name="country"
                label="Country"
                placeholder="Country"
                disabled={isAdminChangeDisable}
              >
                <option value="" />
                {countries.map((option) => (
                  <option key={option.code} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </RHFSelect>

              <RHFTextField
                name="stateOrRegion"
                label="State/Region"
                disabled={isAdminChangeDisable}
              />

              <RHFTextField name="city" label="City" disabled={isAdminChangeDisable} />
              <RHFTextField name="zipCode" label="Zip/Code" disabled={isAdminChangeDisable} />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField
                name="about"
                multiline
                rows={4}
                label="About"
                disabled={isAdminChangeDisable}
              />

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={isAdminChangeDisable}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
