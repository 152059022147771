import { m } from 'framer-motion';

import { alpha, useTheme, styled } from '@mui/material/styles';
import {Box, Button, Card, Container, Typography} from '@mui/material';

import Image from '../../../../shared/components/Image';
import { MotionViewport, varFade } from '../../../../shared/components/animate';
import {useNavigate} from "react-router-dom";
import { PATH_PAGE } from 'src/routes/paths';
import {useCallback, useEffect, useState} from "react";
import {API, API_ENDPOINTS} from "../../../../shared/utils/api";

import { EstateObject } from 'src/shared/@types/blog';

// ----------------------------------------------------------------------

const CARDS = [
  {
    icon: '/assets/home/Consulting.jpg',
    title: 'Consulting Services',
    description:
      'Free consultation is available upon request! Your home search and buying experience is a priority for us.',
  },
  {
    icon: '/assets/home/businessValuation.jpg',
    title: 'Property Management',
    description:
      "We don't only help you to find, buy, or sell your house. Delek homes also provides property management services for your rental properties!",
  },
  {
    icon: '/assets/home/rentingSelling.jpg',
    title: 'Renting & Selling',
    description:
      "Do you wanna buy a house? Do you wanna rent a house? We've got you. Contact us and we will solve all of your real estate problems!",
  },
];

const shadowIcon = (color: string) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}));


const Description = styled(Typography,)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: '4',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const CardStyle = styled(Card)(({ theme }) => {
  const shadowCard = (opacity: number) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    border: 0,
    maxWidth: 380,
    minHeight: 440,
    margin: 'auto',
    textAlign: 'center',
    pb: 5,
    boxShadow: theme.customShadows.z12,
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    '&.cardLeft': {
      [theme.breakpoints.up('md')]: { marginTop: -40 },
    },
    '&.cardCenter': {
      [theme.breakpoints.up('md')]: {
        marginTop: -80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        '&:before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: 'auto',
          position: 'absolute',
          width: 'calc(100% - 40px)',
          height: 'calc(100% - 40px)',
          borderRadius: Number(theme.shape.borderRadius) * 2,
          backgroundColor: theme.palette.background.paper,
          // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
    },
  };
});

// ----------------------------------------------------------------------

export default function HomeMinimal() {
  const [lastPublished, setLastPublished] = useState<EstateObject[] | null>(null);

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const navigate = useNavigate();

  const getLastPublished = useCallback(async () => {
    const response = await API.get<any>(API_ENDPOINTS.GET_LAST_PUBLISHED)

    setLastPublished(response.data.list)
  }, [])

  useEffect(() => {
    getLastPublished();
  }, [])

  console.log(lastPublished)

  const handleSeeMoreClick = () => {
    navigate(PATH_PAGE.realEstate);
  }


  return (
    <RootStyle>
      <Container component={MotionViewport}>
        <Box
          sx={{
            textAlign: 'center',
            mb: { xs: 10, md: 25 },
          }}
        >
          <m.div variants={varFade().inUp}>
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
              Benefits
            </Typography>
          </m.div>
          <m.div variants={varFade().inDown}>
            <Typography variant="h2">Newest Listing</Typography>
          </m.div>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gap: { xs: 5, lg: 10 },
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
          }}
        >
          {lastPublished && lastPublished.map((card, index) => (
            <m.div variants={varFade().inUp} key={card.title}>
              <CardStyle
                className={(index === 0 && 'cardLeft') || (index === 1 && 'cardCenter') || ''}
              >
                <Image
                  src={card.images[0]}
                  alt={card.title}
                  sx={{
                    mb: 5,
                    mx: 'auto',
                    width: '100%',
                    height: 220,
                    filter: (theme) => shadowIcon(theme.palette.primary.main),
                    ...(index === 0 && {
                      filter: (theme) => shadowIcon(theme.palette.info.main),
                    }),
                    ...(index === 1 && {
                      filter: (theme) => shadowIcon(theme.palette.error.main),
                    }),
                  }}
                />
                <Typography variant="h5" paragraph>
                  {card.title}
                </Typography>
                <Box sx={{
                  padding: '0 15px 15px',
                }}>
                  <Description sx={{
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}>
                    {card.description}
                  </Description>
                </Box>
              </CardStyle>
            </m.div>
          ))}
        </Box>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button size="large" variant="outlined" onClick={handleSeeMoreClick}>
            See more featured Listings
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
