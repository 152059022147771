import { useCallback, useEffect, useMemo, useReducer } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {Grid, Card, Stack, Button, Typography, MenuItem} from '@mui/material';

import { PATH_DASHBOARD } from '../../../../routes/paths';
import {
  RHFSwitch,
  FormProvider,
  RHFTextField,
  RHFUploadMultiFile,
} from '../../../../shared/components/hook-form';
import { API, API_ENDPOINTS } from '../../../../shared/utils/api';

import RealEstateNewPreview from './RealEstateNewPreview';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export type FormValuesProps = {
  title: string;
  description: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  price: number | null;
  bedrooms: number | null;
  bathrooms: number | null;
  garage: number | null;
  sqft: number | null;
  lotSize: number | null;
  images: string[];
  isPublished: boolean;
};

type Props = {
  isEdit: boolean;
  currentRealEstate?: any;
  demoValue?: any;
};

const states = [{ value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

export default function RealEstateNewForm({ isEdit, currentRealEstate, demoValue }: Props) {
  const navigate = useNavigate();

  const [open, togglePreview] = useReducer((flag) => !flag, false);

  const { enqueueSnackbar } = useSnackbar();

  const NewEstateSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.number().required('ZipCode is required').typeError('ZipCode must be a number'),
    price: Yup.number().required('Price is required').typeError('Price must be a number'),
    bedrooms: Yup.number().required('Bedrooms is required').typeError('Bedrooms must be a number'),
    bathrooms: Yup.number().required('Bathrooms is required').typeError('Bathrooms must be a number'),
    garage: Yup.number().required('Garage is required').typeError('Garage must be a number'),
    sqft: Yup.number().required('Sqft is required').typeError('Sqft must be a number'),
    lotSize: Yup.number().required('Lot Size is required').typeError('Lot Size must be a number'),
    images: Yup.array().of(Yup.mixed()).min(1, 'At least one image is required'),
  });

  const defaultValues = useMemo(
    () => {
      const initData = demoValue || currentRealEstate;

      if (initData) {
        return {
          title: initData?.title || '',
          description: initData?.description || '',
          address: initData?.address || '',
          city: initData?.city || '',
          state: initData?.state || '',
          zipCode: initData?.zipCode || '',
          price: initData?.price || '',
          bedrooms: initData?.bedrooms || '',
          bathrooms: initData?.bathrooms || '',
          garage: initData?.garage || '',
          sqft: initData?.sqft || '',
          lotSize: initData?.lotSize || '',
          images: initData?.images || [],
          isPublished: initData?.isPublished || false,
        };
      }

      return {
        title: '',
        description: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        price: '',
        bedrooms: '',
        bathrooms: '',
        garage: '',
        sqft: '',
        lotSize: '',
        images: [],
        isPublished: false,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRealEstate, demoValue]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewEstateSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  console.log('values', values);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const formData = new FormData();

      Object.keys(data).forEach((formProp) => {
        // @ts-ignore
        if (formProp === 'images' && data[formProp]) {
          data[formProp].forEach((img: any) => {

            console.log('img', img);

            if (img?.name) {
              // @ts-ignore
              formData.append('images', img, img.name);
            } else {
              formData.append('images', img);
            }
          });
        } else {
          // @ts-ignore
          formData.append(formProp, data[formProp]);
        }
      });

      console.log('formData', formData);

      if (isEdit) {
        await API.put<any>(
          API_ENDPOINTS.UPDATE_REAL_ESTATE.replace(':id', currentRealEstate.id),
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      } else {
        await API.post<any>(API_ENDPOINTS.CREATE_REAL_ESTATE, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }

      reset();
      togglePreview();
      enqueueSnackbar('Post success!');
      navigate(PATH_DASHBOARD.realEstate.list);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isEdit && demoValue) {
      reset(defaultValues);
    }

    if (isEdit && currentRealEstate) {
      reset(defaultValues);
    }
    if (!isEdit && !currentRealEstate && !demoValue) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, demoValue, currentRealEstate]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const images = getValues('images');

      let files: any = acceptedFiles.map((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        return file;
      });

      if (images) {
        files = [...files, ...images];
      }

      if (files) {
        setValue('images', files);
      }
    },
    [setValue]
  );

  const onRemoveAll = () => {
    setValue('images', []);
  };
  const onRemove = (file: File | string) => {
    const images = getValues('images');

    setValue(
      'images',
      images.filter((item) => item !== file)
    );
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <RHFTextField name="title" label="Title" />

                <RHFTextField
                  name="description"
                  label={
                    <>
                      {/*<Iconify icon={'eva:plus-fill'} />*/}
                      Description
                    </>
                  }
                  multiline
                  rows={4}
                />

                <RHFTextField name="city" label="City" />
                <RHFTextField name="address" label="Address" />
                <RHFTextField name="zipCode" label="Zip Code"/>
                <RHFTextField name="state" label="State" select>
                  {states.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                  ))}
                </RHFTextField>

                <div>
                  <LabelStyle>Images</LabelStyle>
                  <RHFUploadMultiFile
                    name="images"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    showPreview={true}
                    onRemoveAll={onRemoveAll}
                    onRemove={onRemove}
                  />
                </div>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <div>
                  <RHFSwitch
                    name="isPublished"
                    label="Publish"
                    labelPlacement="start"
                    sx={{ mb: 1, mx: 0, width: 1, justifyContent: 'space-between' }}
                  />
                </div>
                <RHFTextField name="price" label="$ Price"/>
                <RHFTextField name="bedrooms" label="Bedrooms"/>
                <RHFTextField name="bathrooms" label="Bathrooms"/>
                <RHFTextField name="garage" label="Garage"/>
                <RHFTextField name="sqft" label="Sqft"/>
                <RHFTextField name="lotSize" label="Lot Size"/>
              </Stack>
            </Card>

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
              <Button
                fullWidth
                color="inherit"
                variant="outlined"
                size="large"
                onClick={togglePreview}
              >
                Preview
              </Button>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                loading={isSubmitting}
              >
                {isEdit ? 'Update' : 'Post'}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>

      <RealEstateNewPreview
        values={values}
        isOpen={open}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onClose={togglePreview}
        onSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
}
