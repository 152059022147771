// hooks
import { useMemo } from 'react';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();
  const userDisplayName = `${user?.username} ${user?.user_surname}`

  const currentDomain = process.env.REACT_APP_DOMAIN;

  const scr = useMemo(() => {
    if (!currentDomain) {
      return '/' + user?.avatarUrl || '';
    }


    return currentDomain + '/' + user?.avatarUrl || '';
  }, [user?.avatarUrl])

  return (
    <Avatar
      src={scr}
      alt={`${user?.username}-${user?.user_surname}`}
      color={user?.avatarUrl ? 'default' : createAvatar(userDisplayName).color}
      {...other}
    >
      {createAvatar(userDisplayName).name}
    </Avatar>
  );
}
